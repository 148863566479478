<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="settings-title mb-3">
          {{ $t('components.form.credits-pack.credit-pack-informations.label') }}
        </div>
        <b-row>
          <b-col cols="6">
            <b-row>
              <b-col>
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.credits-pack.credit-pack-informations.name') }}
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <d-text-field
                    v-model="creditPack.name"
                    :rules="validation.name"
                    :place-holder="'components.form.subscriptions.new.name.placeholder'"
                    :class-name="'background-light-blue-inputs'"
                    size="sm"
                    error-text="validation.text-input-three-characters"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.credits-pack.credit-pack-informations.price') }}
                </label>
              </b-col>
              <b-col cols="12">
                <b-input-group class="line-height-0">
                  <b-form-input
                      v-model="creditPack.price"
                      type="number"
                      min="0"
                      size="sm"
                      autocomplete="off"
                      class="background-light-blue-inputs"
                      :placeholder="$t('general.actions.numbers.zero')"
                      @change="requiredPrice = true"
                  />
                  <b-input-group-append is-text>
                    <div> {{ $currency }} </div>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback
                    :state="requiredPrice"
                >
                  {{ $t('validation.required') }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="description-gray" cols="3">
            {{ $t('components.form.credits-pack.credit-pack-informations.descriptions.name') }}
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="9">
            <b-row>
              <b-col cols="12">
                <label class="col-form-label-sm field-name-class">
                  {{ $t('components.form.subscriptions.new.description.label') }}
                </label>
              </b-col>
              <b-col cols="12">
                <b-textarea
                    v-model="creditPack.description"
                    :placeholder="$t('components.form.credits-pack.credit-pack-informations.description-pack-place-holder')"
                    rows="5"
                    size="sm"
                    class="background-light-blue-inputs"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col class="description-gray mt-3" cols="3">
            {{ $t('components.form.credits-pack.credit-pack-informations.descriptions.description') }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  data: () => ({
    expiration: false,
    deeped: false,
    requiredPrice: true,
    validation: require('@validation/entities/doinsport/CreditPack.json')
  }),
  props: {
    validator: {
      type: Boolean,
      default: false
    },
    creditPack: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    validator: {
      deep: true,
      handler () {
        if (this.creditPack.price === null || this.creditPack.price === '') {
          this.requiredPrice = false;
          this.validator.valid = false;
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>

.line-height-0 .input-group-text{
  line-height: 0;
}
</style>


